/* eslint-disable camelcase */
import React, { Component } from 'react';
import { Tab, DropdownItem } from 'react-bootstrap';
import { connect } from 'react-redux';
import moment from 'moment';
import PropTypes from 'prop-types';
import Datetime from 'react-datetime';
import _ from 'lodash';
import { toast } from 'react-toastify';
import { StyledTab, EditProfilePadding, SingleTile, SettingsName, StyledInput, CustomDateTime } from './styles';
import { PageTitle } from '../UserProfile/styles';
import { /*SideBarContainer,*/ Layout, SocialFeedsContainer, FeedsContainer,InputImage } from '../../components/SocialFeeds/styles';
import { CustomCheckbox, CheckMark, InputContainer, DateIconContainer  } from '../../components/CreateEvent/styles';
//import {InputImage} from "../../../SocialFeeds/styles";
// import { FeaturedChallenges } from '../../components';

import { changePassword, putUpdateWellnessData, putUpdateUserData, getUserProfile, getUserHealth, removeProfileAPI, getWellnessInterests, getFitnessGoals } from '../../redux/actions';
import countryData from '../../../countryData.json';
import { RegistrationEdit } from '../../components';

import { displayPhoneNumber, phoneNumberMaker ,checkImage, getOrientation,resetOrientation} from '../../utils/methods';
import UpdatedCredentials from "./UpdateCredentials";
import {uploadProfile} from  '../../redux/actions';
import {ImageUrl, imageErrorMessage} from '../../utils/constants';
import UserEmailNotifications from '../../components/UserEmailNotifications';
import {withTranslation} from 'react-i18next';
import MatrixConverionMain from '../../components/MatrixConversion/index';
import LazyImage from '../../components/common/LazyImage/LazyImage';
import CommonButton from '../../components/common/CommonButton/CommonButton';
import SkeletonLoder from '../../components/common/skeletonLoder';
import { ActivityDropdown } from '../../components/CreateChallengeForm/styles';

const profilePrivacy = {
  name: "Profile Privacy Setting:",
  width: 100,
  marginLeft: 0,
  disable: true,
  details: [
    {
      name: 'privacy1'
    },
    {
      name: 'privacy2'
    },
    {
      name: 'privacy3'
    }
  ]
};

const visiblityDetails= {
  name: 'Visibility:',
  width: 25,
  marginLeft: 10,
  disable: true,
  details: [
    {
      name: 'Only Me'
    },
    {
      name: 'Friends'
    },
    {
      name: 'Public'
    }
  ]
};

// const firstNameDetails = {
//   name: "First Name",
//   width: 65,
//   marginLeft: 0,
//   important: true,
//   visiblityDetails : {
//     name: 'Visibility:',
//     width: 25,
//     marginLeft: 10,
//     disable: true,
//     details: [
//       {
//         name: 'Only Me'
//       },
//       {
//         name: 'Friends'
//       },
//       {
//         name: 'Public'
//       }
//     ]
//   }
// };

// const lastNameDetails = {
//   name: "Last Name",
//   width: 65,
//   marginLeft: 0,
//   important: true,
//   visiblityDetails: {
//     name: 'Visibility:',
//     width: 25,
//     marginLeft: 10,
//     disable: true,
//     details: [
//       {
//         name: 'Only Me'
//       },
//       {
//         name: 'Friends'
//       },
//       {
//         name: 'Public'
//       }
//     ]
//   }
// };

// const companyLocationDetails = {
//   name: 'Company Location: ',
//   width: 60,
//   marginLeft: 0,
//   details: [],
//   important: true
// }

// const genderDetails = {
//   name: "Gender",
//   width: 30,
//   marginLeft: 0,
//   marginRight: 70,
//   details: [
//     {
//       name: 'Male'
//     },
//     {
//       name: 'Female'
//     }
//   ] 
// };

// const countryDetails = {
//   name: "Country:",
//   width: 100,
//   marginLeft: 0,
//   details: countryData.countries
// };

// const addressDetails = {
//   name: 'Address',
//   width: 100,
//   marginLeft: 0
// };

// const cityDetails = {
//   name: 'City/Suburb',
//   width: 40,
//   marginLeft: 0
// };

const stateDetails = {
  name: 'State:',
  width: 100,
  marginLeft: 0,
  details: []
};

// const postalDetails = {
//   name: 'PostCode',
//   width: 40,
//   marginLeft: 20
// };

// const physicalExamDetails = {
//   name: 'Physical Exam Frequency:',
//   width: 100,
//   marginLeft: 0,
//   important: true,
//   details: [
//     {
//       name: 'Every 5+ Year'
//     },
//     {
//       name: 'Every 3+ Year'
//     },
//     {
//       name: 'Every 1+ Year'
//     },
//     {
//       name: 'Every Year'
//     }
//   ]
// };

// const averageWaterDetails = {
//   name: 'Average Water Intake Per Day?',
//   width: 100,
//   marginLeft: 0,
//   important: true,
//   details: [
//     {
//       name: '20 Ounces Or Less'
//     },
//     {
//       name: '20 - 40 Ounces'
//     },
//     {
//       name: '40 - 60 Ounces'
//     },
//     {
//       name: '60 - 80 Ounces'
//     },
//     {
//       name: '80 Ounces Or More'
//     }
//   ]
// };

// const experiencePainDetails = {
//   name: 'How Often Do You Experience Pain In Your Body?',
//   width: 100,
//   marginLeft: 0,
//   important: true,
//   details: [
//     {
//       name: 'Daily'
//     },
//     {
//       name: 'Weekly'
//     },
//     {
//       name: 'Monthly'
//     },
//     {
//       name: 'Annually'
//     },
//     {
//       name: 'Rarely OR Never'
//     }
//   ]
// };

// const averageSleepDetails = {
//   name: 'Average Sleep Per Night?',
//   width: 100,
//   marginLeft: 0,
//   important: true,
//   details: [
//     {
//       name: '9 Hrs'
//     },
//     {
//       name: '8 Hrs'
//     },
//     {
//       name: '7 Hrs'
//     },
//     {
//       name: '6 Hrs Or Less'
//     }
//   ]
// };

// const prescriptionDetails = {
//   name: 'How Many Prescription Drugs Do You Take Daily?',
//   width: 100,
//   marginLeft: 0,
//   important: true,
//   details: [
//     {
//       name: '0'
//     },
//     {
//       name: '1'
//     },
//     {
//       name: '2'
//     },
//     {
//       name: '3+'
//     }
//   ]
// };

const benFirstNameDetails = {
  name: "Beneficiary First Name",
  width: 100,
  marginLeft: 0
};
  
const benLastNameDetails = {
  name: "Beneficiary Last Name",
  width: 100, 
  marginLeft: 0
};
  
const benRelationship = {
  name: "Beneficiary Relationship",
  width: 100,
  marginLeft: 0
};

const BenEmployeePhoneNumber = {
  name: "Beneficiary Phone Number",
  width: 60,
  marginLeft: 0
};

// const exerciseDetail = {
//   name: 'How Many Days Per Week Do You Exercise?',
//   width: 100,
//   marginLeft: 0,
//   important: true,
//   details: [
//     {
//       name: '0 Days'
//     },
//     {
//       name: '1 Days'
//     },
//     {
//       name: '2 Days'
//     },
//     {
//       name: '3 Days'
//     },
//     {
//       name: '4 Days'
//     },
//     {
//       name: '5 Days'
//     },
//     {
//       name: '6 Days'
//     },
//     {
//       name: '7 Days'
//     }
//   ]
// };


const WellnessData = [
  "individual sports",
  "water sports",
  "ball sports",
  "wheel sports",
  "winter sports",
  "combat sports",
  "resistance training",
  "other"
];

const fitnessData = [
  'health',
  'behavioral',
  'performance',
  'racing/competition'
];

class Settings extends Component {
  constructor() {
    super();
    this.state = {
      privacyDropdown: '-None-',
      //firstNameVisiblityDropdown: firstNameDetails.visiblityDetails.details[2].name,
      //lastNameVisiblityDropdown: lastNameDetails.visiblityDetails.details[2].name,
      dateOfBirthVisiblity: visiblityDetails.details[2].name,
      genderDropdown: '',
      countryDropdown: '',
      stateDropdown: '',
      stateDropdownDetails: stateDetails,
      physicalExamDropdown: '-Select a Value-',
      averageWaterDropdown: '-Select a Value-',
      experiencePainDropdown: '-Select a Value-',
      prescriptionDropdown: '-Select a Value-',
      smoke: '-Select a Value-',
      exercise: '-Select a Value',
      firstName: ' ',
      lastName: ' ',
      phoneNumber: ' ',
      benFirstName: ' ',
      benLastName: ' ',
      benRelation: ' ',
      benPhoneNumber: ' ',
      address1: ' ',
      address2: ' ',
      city: ' ',
      postalCode: ' ',
      birthDate: ' ',
      benBirthDate: ' ',
      showDate: '',
      goalArr: [],
      interestArr: [],
      wellnessSave: true,
      disableButtton: '',
      enableButtton :'',
      openedDropDown: '',
      count: 0,
      companyLocation: '',
      companyDepartment: '',
      averageSleepDropdown: '-Select a Value-',
      hide: false,
      redirectToTop:false,
      minuteActiveDropdown: '-Select a Value-',
    }
  }

  disableButtonFunction = (value) => {
    this.setState({
      disableButtton: value
    })
  }
  

  removeUserPic = () => {
    if(this.state.disableButtton != 'remove_profile') {
      let obj={};
      const { user, removeProfileAPI } = this.props;
      obj['uid'] = user['uid'];
      this.setState({
        disableButtton: 'remove_profile'
      },() => {
        removeProfileAPI(obj, this.disableButtonFunction);
      })
    }
  };

  onChange = (e) => {
    let file = e.target.files[0];
    if(checkImage(e.target.files)) {
      if((file?.size/1000000) <= 20){
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
          this.setState({
            imgSrc: reader.result,
            hide: true
          });
          getOrientation(file, (or) => {
            resetOrientation([reader.result], or, (baseImage) => {
              this.props.uploadProfile({profile_image: baseImage});
              this.setState({
                imgSrc: baseImage,
                hide: false
              });
            });
          });
        };
      } else {
        toast.error('Please select image file less than 20MB');
        const uploadPhotoElement = document.getElementById('uploadPhoto');
        if(uploadPhotoElement){
          document.getElementById('profle-file').value = ''; 
        }
      }
    } else {
      toast.error(imageErrorMessage);
      const profileFileElement = document.getElementById('profle-file');
      if(profileFileElement){
        document.getElementById('profle-file').value = '';
      }
    }
  };


  componentDidMount () {
    const { fitnessGoals, wellnessInterests, user, health, getUserProfileDetails, 
      getUserHealthDetails, fetchWellnessInterest, fetchFitnessGoals, interest, goal} = this.props;
    if(!user || !health || !wellnessInterests || !fitnessGoals || !interest || !goal ) {
      getUserProfileDetails();
      getUserHealthDetails();
      fetchWellnessInterest();
      fetchFitnessGoals();
    
    }
    document.addEventListener('mousedown', this.handleClick);
  }

  componentDidUpdate() {
    if(this.state.redirectToTop){
      window.scrollTo(0,0);
      window.setTimeout(() => {
        this.setState({ redirectToTop: false });
      }, 3000);
    }
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClick);
  }

  handleClick = (e) => {
    if (typeof(this.datePickerWrapper) === 'undefined' && this.datePickerWrapper === null) {
      return 1
    }
    if(!(_.isNull(this.datePickerWrapper)) && !(_.isUndefined(this.datePickerWrapper))){
      if(this.datePickerWrapper && !this.datePickerWrapper.contains(e.target)){
        this.setState({
          showDate: ''
        });
      }
    }
  };

  setDatePickerWrapper = (node) =>  {
    this.datePickerWrapper = node;
  };

  getIndex = (arr, id) => {
    let index;
    for(let i=0; i<arr.length;){
      if(arr[i] == id) {
        index = i;
        i = arr.length;
      }
      else {
        i+=1;
      }
    }
    return index;
  };

  validateCheckbox = (objectName) => {
    if(this.state[objectName].length > 1) {
      return true;
    }
    else {
      toast.info('At least one selection is required');
      return false;
    }
  };
  
  validateGoalCheckbox = (objectName) => {
    if(this.state[objectName].length < 5) {
      return true;
    } 
    else {
      toast.info('Up to 5 choices allowed');
      return false;
    }
  };

  onCheckboxData = (objectName, id) => {
    const arr = this.state[objectName];
    if(arr.includes(id)) {
      if(this.validateCheckbox(objectName)) {
        let index = this.getIndex(arr, id);
        arr.splice(index, 1)
      }
    }
    else {
      if(objectName == 'goalArr') {
        if(this.validateGoalCheckbox(objectName)) {
          arr.push(id);
        }
      }
      else {
        arr.push(id);
      }
    }
    this.setState({
      [objectName]: arr
    })
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.updateStateInputValue(nextProps);
    this.enableCheckingWellness();
    const { interest, goal } = nextProps;
    if(interest) {
      let arr = [];
      for(let i=0; i<interest.length;i++) {
        arr.push(interest[i].interest_id)
      }
      this.setState({
        interestArr: arr
      })
    }
    if(goal) {
      let arr = []
      for(let i=0; i<goal.top_goals.length;i++) {
        arr.push(goal.top_goals[i].id)
      }
      for(let i=0; i<goal.other_goals.length;i++) {
        arr.push(goal.other_goals[i].id)
      }
      this.setState({
        goalArr: arr
      })
    }
  }

  enableCheckingWellness = () => {
    const { physicalExamDropdown, averageWaterDropdown, experiencePainDropdown, 
      prescriptionDropdown, smoke, exercise, averageSleepDropdown, minuteActiveDropdown } = this.state;
    if( (physicalExamDropdown != '-Select a Value-') && (averageWaterDropdown!= '-Select a Value-')  
      && (experiencePainDropdown!='-Select a Value-') && (prescriptionDropdown!= '-Select a Value-') && (minuteActiveDropdown!='-Select a Value-')
      && (smoke!= '-Select a Value-') && (exercise!= '-Select a Value-') && (averageSleepDropdown!= '-Select a Value-')) {
      this.setState({
        wellnessSave: false
      })
    }
    else {
      this.setState({
        wellnessSave: true
      }) 
    }
  };

  updateStateInputValue = (nextProps) => {
    if(nextProps.user && nextProps.health) {
      const userProfile = nextProps.user;
      this.setState({
        companyDepartment: userProfile.department_id,
        companyLocation: userProfile.city_state_id,
        firstName: userProfile.fname,
        lastName: userProfile.lname,
        birthDate: userProfile.birthday,
        genderDropdown: userProfile.gender/*||genderDetails.details[0].name,*/,
        phoneNumber: displayPhoneNumber(userProfile.phone_number) || '',
        benFirstName: userProfile.benef_fname || '',
        benLastName: userProfile.benef_lname || '',
        benRelation: userProfile.benef_relation || '',
        benPhoneNumber: userProfile.benef_phone || '',
        address1: userProfile.address1 || '',
        address2: userProfile.address2 || '',
        city: userProfile.user_city || '',
        stateDropdown: userProfile.user_state || '',
        postalCode: userProfile.postal_code || '',
        benBirthDate: userProfile.benef_birth || '',
        countryDropdown: userProfile.country || '',
        physicalExamDropdown: userProfile.user_type !== 2 ? (nextProps.health[0].physical_exam_frequency || '-Select a Value-'): '',
        averageWaterDropdown: userProfile.user_type !== 2 ? (nextProps.health[0].average_water_intake || '-Select a Value-') : '',
        experiencePainDropdown: userProfile.user_type !== 2 ? (nextProps.health[0].pain_experience || '-Select a Value-') : '',
        prescriptionDropdown: userProfile.user_type !== 2 ? (nextProps.health[0].prescription_intake || '-Select a Value-') : '',
        smoke:  userProfile.user_type !== 2 ? (nextProps.health[0].do_you_smoke || '-Select a Value-') : '',
        exercise: userProfile.user_type !== 2 ? (nextProps.health[0].exercise_day_per_week || '-Select a Value-'): '',
        averageSleepDropdown: userProfile.user_type !== 2 ? (nextProps.health[0].average_sleep_per_night || '-Select a Value-') : '',
        minuteActiveDropdown: userProfile.user_type !== 2 ? (nextProps.health[0].minute_active || '-Select a Value-') : '',
      }, () => {
        if(userProfile.country) {
          for (let i=0; i < countryData.countries.length; i++) {
            if(countryData.countries[i].country == userProfile.country) {
              const { stateDropdownDetails } = this.state;
              let duplicateDetails = stateDropdownDetails;
              duplicateDetails.details = countryData.countries[i].states;
              this.setState({
                stateDropdownDetails: duplicateDetails
              }) 
            }
          }
        }
      })
    }
  };


  updateWellnessDashboard = () => {
    if( this.state.disableButtton != 'save_wellness') {
      let obj = {}
      const { updateWellnessDetail } = this.props;
      const { physicalExamDropdown, averageWaterDropdown, experiencePainDropdown, 
        prescriptionDropdown, goalArr, interestArr, smoke, exercise , averageSleepDropdown, minuteActiveDropdown} = this.state
      obj.average_sleep_per_night = averageSleepDropdown;
      obj.physical_exam_frequency = physicalExamDropdown;
      obj.average_water_intake = averageWaterDropdown;
      obj.pain_experience = experiencePainDropdown;
      obj.prescription_intake = prescriptionDropdown;
      obj.long_term = goalArr;
      obj.aoi = interestArr;
      obj.smoke = smoke;
      obj.exercise_day_per_week = exercise;
      obj.minute_active = minuteActiveDropdown
      this.setState({
        disableButtton: 'save_wellness'
      }, () => {
        updateWellnessDetail(obj, this.disableButtonFunction);
      })
    }
  };

  updateUserProfile = () => {
    if(this.state.disableButtton != 'save_personal') {
      const { updateUserDataDetails, locationDetails, departmentDetails } = this.props;
      const { genderDropdown, countryDropdown, stateDropdown, firstName, lastName, phoneNumber, benFirstName,
        benLastName, benRelation, benPhoneNumber, address1, address2, city, postalCode, birthDate, 
        benBirthDate, companyDepartment, companyLocation } = this.state;
      const obj = {};
      obj.fname = firstName;
      obj.lname = lastName;
      obj.birthday = birthDate;
      obj.sex = genderDropdown;
      obj.phone_number = phoneNumberMaker(phoneNumber);
      obj.country = countryDropdown;
      obj.benef_fname = benFirstName;
      obj.benef_lname = benLastName;
      obj.benef_relation = benRelation;
      obj.benef_birth = benBirthDate;
      obj.benef_phone = benPhoneNumber;
      obj.address1 = address1;
      obj.address2 = address2;
      obj.state = stateDropdown;
      obj.city = city;
      obj.postal_code = postalCode;
      if(locationDetails) {
        obj.city_state_id = companyLocation;

      }
      if(departmentDetails) {
        obj.department_id = companyDepartment;
      }
      this.setState({
        disableButtton: 'save_personal', 
        redirectToTop:true
      }, () => {
        updateUserDataDetails(obj, this.disableButtonFunction);
      })
    }
  };

  changeDropdownDetails = (stateName, event, list) => {
    this.setState({
      [stateName]: list.details[event-1].name || list.details[event-1],
      openedDropDown: ''
    },() => (
      this.enableCheckingWellness()
    ));
  };

  changeInputDetails = (stateName, event) => {
    const reg = /^[0-9 -]+$/;
    if(stateName == 'phoneNumber' || stateName == 'postalCode') {
      if(stateName == 'phoneNumber') {
        if(event.target.value.length <= 12) {
          if(event.target.value == '' || reg.test(event.target.value)) {
            let phoneValue = '';
            if(this.state.phoneNumber.length < event.target.value.length) {
              phoneValue = (event.target.value.length === 3 || event.target.value.length === 7) ? event.target.value+'-' : event.target.value;
            }
            else {
              if(this.state.phoneNumber[this.state.phoneNumber.length-1] == '-') {
                phoneValue = this.state.phoneNumber.slice(0,-1);
                phoneValue = phoneValue.slice(0,-1);
              }
              else {
                phoneValue = event.target.value;
              }
            }
            this.setState({
              [stateName]: phoneValue
            },() => {
              if(this.state.phoneNumber.length < 12 && this.state.phoneNumber !== '') {
                this.setState({
                  disableButtton: 'save_personal'
                });
              }
              else {
                this.setState({
                  disableButtton: ''
                });
              }
            });
          }
        }
      }
      else {
        if(event.target.value.length <= 6) {
          if(event.target.value == '' || reg.test(event.target.value)) {
            this.setState({
              [stateName]: event.target.value
            });
          }
        }
      }
    }
    else {
      if(stateName == 'firstName' || stateName == 'lastName') {
        if(event.target.value.length <= 30 && event.target.value !== ' ') {
          this.setState({
            [stateName]: event.target.value.replace(/[&\\#,+()$~%@^!_.'":*?<>{}`|0-9]/g, "")
          });
        }
      }
      else {
        this.setState({
          [stateName]: event.target.value
        });
      }
    }
  };

  changeOpenDrop = (stateName) => {
    if(stateName === this.state.openedDropDown) {
      this.setState({ openedDropDown: ''})
    }
    else {
      this.setState({ openedDropDown: stateName})
    }
  }

  dropDownDetails = (dropDown, title, stateName, hideDropDown) => {
    let fontSize = 0;
    if (title == '-None-') {
      fontSize = 1;
    }
    const {t} = this.props;
    return (
      <SingleTile 
        width={dropDown.width} 
        marginLeft={dropDown.marginLeft} 
        marginRight={dropDown.marginRight}
        hideDropDown={hideDropDown}
      >
        <div style={{display:"flex",flexDirection:"column"}}>
          <SettingsName style={{marginBottom:"15px"}}>
            <div>{t(dropDown.name)}
              { 
                dropDown.important
                  ?
                  <span className="important">*</span>
                  :
                  ''         
              }
            </div>
          </SettingsName>
          <ActivityDropdown
            showfont={fontSize}
            title={dropDown.details.length < 1 ? t('No states') : <div className="settingOption">{t(title)}</div>}
            disabled={dropDown.details.length < 1 || dropDown.disable}
            id="dropdown-size-medium"
            // onClick={() => this.changeOpenDrop(stateName)}
            // onBlur={() => this.changeOpenDrop(stateName)}
            onSelect={(event) => this.changeDropdownDetails(stateName, event, dropDown)}
            // opened={stateName===this.state.openedDropDown ? 1 : 0}
            // block
          >
            {
              dropDown.details.map((menu, index) =>
                (<DropdownItem eventKey={index+1} key={index}>{t(menu.name) || t(menu)}</DropdownItem>)
              )
            }
          </ActivityDropdown>
        </div>
      </SingleTile>
    )
  }; 
  
  countryDropDownDetails = (dropDown, title, stateName) => {
    let fontSize = 0;
    if (title == '-None-') {
      fontSize = 1;
    }
    return (
      <SingleTile 
        width={dropDown.width} 
        marginLeft={dropDown.marginLeft} 
        marginRight={dropDown.marginRight}
      >
        <div style={{display:"flex",flexDirection:"column"}}>
          <SettingsName >
            {dropDown.name}
            { 
              dropDown.important
                ?
                <span className="important">*</span>
                :
                ''         
            }
          </SettingsName>
          <ActivityDropdown
            showfont={fontSize}
            title={title}
            id="dropdown-size-medium"
            onSelect={(event) => this.changeCountryDropdownDetails(stateName, event, dropDown)}
            onClick={() => this.changeOpenDrop(stateName)}
            opened={stateName===this.state.openedDropDown ? 1 : 0}
            onBlur={() => this.changeOpenDrop(stateName)}
            block
          >
            {
              dropDown.details.map((menu, index) =>
                (<DropdownItem eventKey={index+1} key={index}>{this.props.t(menu.country)}</DropdownItem>)
              )
            }
          </ActivityDropdown>
        </div>
      </SingleTile>
    )
  };
  
  changeCountryDropdownDetails = (stateName, event, list) => {
    const { stateDropdownDetails, countryDropdown } = this.state;
    if (countryDropdown != list.details[event-1].country ) {
      let duplicateDetails = stateDropdownDetails;
      duplicateDetails.details = list.details[event-1].states;
      this.setState({
        [stateName]: list.details[event-1].country,
        stateDropdownDetails: duplicateDetails,
        stateDropdown: '',
        openedDropDown: ''
      });
    }
  };

  changeCompanyDropDown = (stateName, value) => {
    this.setState({
      [stateName]: value
    })
  }

  companyDropDown = (dropDown, details, stateName, keyValue) => {
    let index = details.findIndex((list) => list.id === this.state[stateName]);
    let selectValue = ''
    if(index > -1) {
      selectValue = details[index][keyValue];
    }
    else {
      selectValue = 'None';
    }

    return (
      <SingleTile 
        width={dropDown.width} 
        marginLeft={dropDown.marginLeft} 
        marginRight={dropDown.marginRight}
      >
        <div style={{display:"flex",flexDirection:"column"}}>
          <SettingsName style={{marginBottom:"15px"}}>
            {this.props.t(dropDown.name)}
            { 
              dropDown.important
                ?
                <span className="important">*</span>
                :
                ''         
            }
          </SettingsName>
          <ActivityDropdown
            title={selectValue}
            id="dropdown-size-medium"
            onClick={() => this.changeOpenDrop(stateName)}
            onBlur={() => this.changeOpenDrop(stateName)}
            opened={stateName===this.state.openedDropDown ? 1 : 0}
            block
          >
            {
              details.map((menu, index) =>
                (<DropdownItem  
                  onSelect={() => this.changeCompanyDropDown(stateName, menu.id)} 
                  eventKey={index+1} 
                  key={index}
                  onClick={() => this.changeCompanyDropDown(stateName, menu.id)} 
                >
                  {menu[keyValue] || menu}
                </DropdownItem>)
              )
            }
          </ActivityDropdown>
        </div>
      </SingleTile>
    )
  }

  inputDetails = (fieldDetails, inputValue, stateName) => (
    <SingleTile width={fieldDetails.width} marginLeft={fieldDetails.marginLeft}>
      <SettingsName>
        {fieldDetails.name+': '}
        { 
          fieldDetails.important
            ?
            <span className="important">*</span>
            :
            ''         
        }
      </SettingsName>
      <StyledInput  
        onChange={(event) => this.changeInputDetails(stateName, event)} 
        value={ !inputValue ? '' : inputValue} 
      />
    </SingleTile>
  );

  showDatePicker = (value) => {
    if(this.state.showDate === value) {
      this.setState({
        showDate: ''
      });
    } else {
      this.setState({
        showDate: value
      });
    }
  };

  changeDate = (e, stateName) => {
    let value = moment();
    value = moment(e._d).format('YYYY-MM-DD');
    this.setState({
      [stateName]: value,
      showDate: ''
    });
  };

  closeDate = (e) => {
    if(e.target.className === 'form-control') {
      if(this.state.count>0 && e.target.className === 'form-control' && this.state.showDate == 'birthDate') {
        this.setState({ showDate: '' })
      }
      else {
        this.setState({  count: 1 })
      }
    }
  }


  render() {    
    const { privacyDropdown, firstNameVisiblityDropdown, lastNameVisiblityDropdown, 
      genderDropdown, countryDropdown, stateDropdown, physicalExamDropdown, averageWaterDropdown,
      experiencePainDropdown, prescriptionDropdown, firstName, lastName, phoneNumber, benFirstName,
      benLastName, benRelation, benPhoneNumber, address1, address2, city, postalCode, birthDate, 
      showDate, benBirthDate, dateOfBirthVisiblity, stateDropdownDetails, goalArr, interestArr, 
      exercise, smoke, wellnessSave, disableButtton, companyLocation, companyDepartment, averageSleepDropdown, minuteActiveDropdown } = this.state;

    const { interest, user, health, wellnessInterests, fitnessGoals, goal, locationDetails, 
      departmentDetails, /*history,*/t} = this.props;
    

    const firstNameDetails = {
      name: t("First Name"),
      width: 65,
      marginLeft: 0,
      important: true,
      visiblityDetails : {
        name: 'Visibility:',
        width: 25,
        marginLeft: 10,
        disable: true,
        details: [
          {
            name: 'Only Me'
          },
          {
            name: 'Friends'
          },
          {
            name: 'Public'
          }
        ]
      }
    };
    
    const employeePhoneNumber = {
      name: t("Employee Phone Number"),
      width: 60,
      marginLeft: 0,
    };
  
    const companyLocationDetails = {
      name: t('Company Location'),
      width: 60,
      marginLeft: 0,
      details: [],
      important: true
    }
    const lastNameDetails = {
      name: t("Last Name"),
      width: 65,
      marginLeft: 0,
      important: true,
      visiblityDetails: {
        name: 'Visibility:',
        width: 25,
        marginLeft: 10,
        disable: true,
        details: [
          {
            name: 'Only Me'
          },
          {
            name: 'Friends'
          },
          {
            name: 'Public'
          }
        ]
      }
    };

    const genderDetails = {
      name: t("Gender"),
      width: 30,
      marginLeft: 0,
      marginRight: 70,
      details: [
        {
          name: t('Male')
        },
        {
          name: t('Female')
        }
      ] 
    };
  
    const countryDetails = {
      name: t("Country"),
      width: 100,
      marginLeft: 0,
      details: countryData.countries
    };

    const addressDetails = {
      name: t('Address'),
      width: 100,
      marginLeft: 0
    };
    const cityDetails = {
      name: t('City/Suburb'),
      width: 40,
      marginLeft: 0
    };
    
    const postalDetails = {
      name: t('PostCode'),
      width: 40,
      marginLeft: 20
    };
    
    const smokeDetails = {
      name: t('Do You Smoke'),
      width: 100,
      marginLeft: 0,
      important: true,
      details: [
        {
          name: t('Yes (Heavy Smoker)')
        },
        {
          name: t('Yes (Casual Smoker / Trying To Quit)')
        },
        {
          name: t("No (I Don't Smoke At All)")
        }
      ]
    };
    

    const physicalExamDetails = {
      name: t('Physical Exam Frequency'),
      width: 100,
      marginLeft: 0,
      important: true,
      details: [
        {
          name: `${("Every")} 5+ ${("Year")}`
        },
        {
          name: `${("Every")} 3+ ${("Year")}`
        },
        {
          name: `${("Every")} 1+ ${("Year")}`
        },
        {
          name: `${("Every")} ${("Year")}`
        }
      ]
    };

    // const stateDetails = {
    //   name: 'State:',
    //   width: 100,
    //   marginLeft: 0,
    //   details: []
    // };
    

    const exerciseDetail = {
      name: t('How Many Days Per Week Do You Exercise'),
      width: 100,
      marginLeft: 0,
      important: true,
      details: [
        {
          name: t(`0 ${("Days")}`)
        },
        {
          name: t(`1 ${("Days")}`)
        },
        {
          name: t(`2 ${("Days")}`)
        },
        {
          name: t(`3 ${("Days")}`)
        },
        {
          name: t(`4 ${("Days")}`)
        },
        {
          name: t(`5 ${("Days")}`)
        },
        {
          name: t(`6 ${("Days")}`)
        },
        {
          name: t(`7 ${("Days")}`)
        }
      ]
    };

    const averageSleepDetails = {
      name: t('Average Sleep Per Night'),
      width: 100,
      marginLeft: 0,
      important: true,
      details: [
        {
          name: '9 Hrs'
        },
        {
          name: '8 Hrs'
        },
        {
          name: '7 Hrs'
        },
        {
          name: '6 Hrs Or Less'
        }
      ]
    };

    const averageWaterDetails = {
      name: t('Average Water Intake Per Day'),
      width: 100,
      marginLeft: 0,
      important: true,
      details: [
        {
          name: '20 Ounces Or Less'
        },
        {
          name: '20 - 40 Ounces'
        },
        {
          name: '40 - 60 Ounces'
        },
        {
          name: '60 - 80 Ounces'
        },
        {
          name: '80 Ounces Or More'
        }
      ]
    };

    const experiencePainDetails = {
      name: t('How Often Do You Experience Pain In Your Body?'),
      width: 100,
      marginLeft: 0,
      important: true,
      details: [
        {
          name: t('Daily')
        },
        {
          name: t('Weekly')
        },
        {
          name: t('Monthly')
        },
        {
          name: t('Annually')
        },
        {
          name: t('Rarely OR Never')
        }
      ]
    };


    const prescriptionDetails = {
      name: t('How Many Prescription Drugs Do You Take Daily'),
      width: 100,
      marginLeft: 0,
      important: true,
      details: [
        {
          name: '0'
        },
        {
          name: '1'
        },
        {
          name: '2'
        },
        {
          name: '3+'
        }
      ]
    };

    const minuteActiveDetails = {
      name: t('How many total minutes per day are you active? (eg. chores, walking the dog, playing with the kids)'),
      width: 100,
      marginLeft: 0,
      important: true,
      details: [
        {
          name: t('Less Than 15 Minutes')
        },
        {
          name: t('15 - 30 Minutes')
        },
        {
          name: t('30 - 45 Minutes')
        },
        {
          name: t('45+ Minutes')
        }
      ]
    };
    
    const companyDepartmentDetails = {
      name: 'Company Department: ',
      width: 60,
      marginLeft: 0,
      important: true
    }

    const yesterday = Datetime.moment().subtract(13, 'years');
    const valid = (currentDate) => currentDate.isBefore(yesterday);
    if(!goal ||!interest || !fitnessGoals||_.isUndefined(user) || _.isNull(user) || _.isUndefined(health) || _.isNull(health) || !wellnessInterests) {
      return <div>
        <SkeletonLoder width={"200px"} height={"26px"} style={{margin:'40px 33px 10px'}}/>
        <SkeletonLoder width={"1246px"} height={"61px"} style={{margin:'20px 20px 1px 33px'}}/>
        <SkeletonLoder width={"942px"} height={"1562px"} style={{margin:'47px 35px 20px 30px'}}/>
      </div>
    }

    return (
      <div>
        <PageTitle>{t("Settings")}</PageTitle>
        <StyledTab defaultActiveKey={1} id="styledTabs">
          <Tab eventKey={1} title={t("EDIT PROFILE")}>
            <Layout>
              <SocialFeedsContainer>
                <FeedsContainer>
                  <EditProfilePadding>
                    {/* <SaveButton onClick={this.removeUserPic} float={1} disabled={user.profile_image == 'profile-Image/default.png' || disableButtton=='remove_profile'}>
                      Remove Profile Picture
                    </SaveButton>   */}
                    {/* { user.profile_image == 'profile-Image/default.png' || disableButtton=='remove_profile' ?
                      (
                        <SaveButton  float={1}>
                    Add Profile Picture
                        </SaveButton>
                      ) : (
                        <SaveButton onClick={this.removeUserPic} float={1}>
                       Remove Profile Picture
                        </SaveButton>
                      )
                    }   */}

                    { user.profile_image !== '' &&
                          user.profile_image !== 'profile-Image/default.png'  ?
                      (    
                        // <SaveButton onClick={this.removeUserPic} float={1} >
                        //   {t("Remove Profile Picture")}
                        // </SaveButton>
                        <CommonButton
                          btnType={"rounded"}
                          onClick={this.removeUserPic}
                          styles={{float:'right'}}
                          title={t("Remove Profile Picture")}
                        />
                      ) :
                      <InputImage className="edit-profile-pic" imageReload={1}>
                        <i className="addImg">{t('Add Profile Picture')}</i>
                        <input
                          id="profle-file"
                          type="file"
                          name="user-profile"
                          multiple={false}
                          accept=".jpeg, .png, .jpg"
                          onChange={(e) => this.onChange(e)}
                          onClick={(e) => e.target.files[0] && this.onChange(e)}
                        />
                      </InputImage>
                    }

                  </EditProfilePadding>
                  <EditProfilePadding bottomBorder={true}>
                    { this.dropDownDetails(profilePrivacy, privacyDropdown, 'privacyDropdown', true) }
                    { this.inputDetails(firstNameDetails, firstName, 'firstName') }            
                    { this.dropDownDetails(firstNameDetails.visiblityDetails, firstNameVisiblityDropdown, 'firstNameVisiblityDropdown', true) }
                    { this.inputDetails(lastNameDetails, lastName, 'lastName') }            
                    { this.dropDownDetails(lastNameDetails.visiblityDetails, lastNameVisiblityDropdown, 'lastNameVisiblityDropdown', true) }
                    <SingleTile width={50} marginRight={15} marginLeft={0}>
                      <SettingsName padding={true}>{t('Date of Birth')}<span className="important">*</span></SettingsName>
                      <InputContainer widthInput={1} innerRef={(node) => showDate === 'birthDate' && this.setDatePickerWrapper(node)}>
                        <div onClick={(e) => this.closeDate(e)}>
                          <CustomDateTime
                            open={showDate === 'birthDate'}
                            inputProps={{ placeholder: moment().format('YYYY-MM-DD'), readOnly: true}}
                            dateFormat="MM/DD/YYYY"
                            closeOnSelect={true}
                            closeOnTab={true}
                            timeFormat={false}
                            value={moment(birthDate).format('MM/DD/YYYY') || ''}
                            isValidDate={valid}
                            viewDate={birthDate ? birthDate : yesterday}
                            onChange={(e) => this.changeDate(e, 'birthDate')}
                            onFocus={() => this.showDatePicker('birthDate') }
                            viewMode="years" 
                          />
                          <DateIconContainer onClick={() => this.showDatePicker('birthDate')}>
                            <LazyImage src={ImageUrl + "/images/calender.png"} alt="calender"/>
                          </DateIconContainer>
                        </div>
                      </InputContainer>
                    </SingleTile>
                    { this.dropDownDetails(visiblityDetails, dateOfBirthVisiblity, 'dateOfBirthVisiblity', true) }
                    { this.dropDownDetails(genderDetails, genderDropdown, 'genderDropdown') }
                    { user && user.user_type === 0 && !user.is_user_deleted && locationDetails && locationDetails.length > 0 &&
                      this.companyDropDown(companyLocationDetails, locationDetails, 'companyLocation', 'location') 
                    }
                    { user && user.user_type === 0 && !user.is_user_deleted && departmentDetails && departmentDetails.length > 0 &&
                      this.companyDropDown(companyDepartmentDetails, departmentDetails, 'companyDepartment', 'department') 
                    }
                    { this.inputDetails(employeePhoneNumber, phoneNumber, 'phoneNumber')}
                  </EditProfilePadding>
                  <EditProfilePadding bottomBorder={true} displayNone>
                    { this.inputDetails(benFirstNameDetails, benFirstName, 'benFirstName') }
                    { this.inputDetails(benLastNameDetails, benLastName, 'benLastName') }
                    { this.inputDetails(benRelationship, benRelation, 'benRelation') }
                    <SingleTile width={50} marginLeft={0} marginRight={50}>
                      <SettingsName padding={true}>{t("Beneficiary Birthdate Date")}: </SettingsName>
                      <InputContainer widthInput={1}>
                        <div ref={(node) => showDate === 'benBirthDate' && this.setDatePickerWrapper(node)}>
                          <CustomDateTime
                            open={showDate === 'benBirthDate'}
                            inputProps={{ placeholder: moment().format('MM/DD/YYYY'), readOnly: true}}
                            dateFormat="YYYY-MM-DD"
                            closeOnSelect={true}
                            closeOnTab={true}
                            timeFormat={false}
                            value={benBirthDate && benBirthDate}
                            isValidDate={valid}
                            viewDate={yesterday}
                            onChange={(e) => this.changeDate(e, 'benBirthDate')}
                          />
                          <DateIconContainer onClick={() => this.showDatePicker('benBirthDate')}>
                            <LazyImage src={ImageUrl + "/images/calender.png"} alt="calender"/>
                          </DateIconContainer>
                        </div>
                      </InputContainer>
                    </SingleTile>
                    { this.inputDetails(BenEmployeePhoneNumber, benPhoneNumber, "benPhoneNumber") }
                  </EditProfilePadding>
                  <EditProfilePadding bottomBorder={true} displayNone>
                    <SingleTile width={40} marginLeft={0} marginRight={5}>
                      <CustomCheckbox>{t("Biometric Screening")}
                        <input
                          type="checkbox"
                          checked={true}
                          disabled={true}
                        />
                        <CheckMark checked={true} />
                      </CustomCheckbox>
                    </SingleTile>
                    <SingleTile width={30} marginLeft={0}>
                      <CustomCheckbox>{t("Flu Shot")}
                        <input
                          type="checkbox"
                          checked={true}
                          disabled={true}
                        />
                        <CheckMark checked={true} />
                      </CustomCheckbox>
                    </SingleTile>
                  </EditProfilePadding>
                  <EditProfilePadding bottomBorder={true}>
                    { this.countryDropDownDetails(countryDetails, countryDropdown, 'countryDropdown') }
                    { this.inputDetails(addressDetails, address1, 'address1') }
                    <StyledInput
                      marginTop={true}
                      onChange={(event) => this.changeInputDetails('address2', event)}
                      value={address2}
                    />
                    { this.dropDownDetails(stateDropdownDetails, stateDropdown, 'stateDropdown') }
                    { this.inputDetails(cityDetails, city, 'city') }
                    { this.inputDetails(postalDetails, postalCode, 'postalCode') }
                  </EditProfilePadding>
                  <EditProfilePadding>
                    {/* <SaveButton onClick={this.updateUserProfile} addMargin 
                      disabled={firstName.trim() == ''|| lastName.trim() == '' || disableButtton == 'save_personal' || (user && user.user_type === 0 && locationDetails && locationDetails.length > 0 && companyLocation == (''||null)) || ( user && user.user_type === 0 && departmentDetails && departmentDetails.length > 0 && companyDepartment == (''||null))}>
                      {t('SAVE')}
                    </SaveButton> */}
                    <CommonButton
                      onClick={this.updateUserProfile}
                      btnType={"rounded"}
                      addMargin
                      disabled={firstName.trim() == ''|| lastName.trim() == '' || disableButtton == 'save_personal' || (user && user.user_type === 0 && locationDetails && locationDetails.length > 0 && companyLocation == (''||null)) || ( user && user.user_type === 0 && departmentDetails && departmentDetails.length > 0 && companyDepartment == (''||null))}
                      styles={{width:'158px', height:'35px', marginBottom:'55px'}}
                      title={t('SAVE')}
                    />
                  </EditProfilePadding>
                </FeedsContainer>
              </SocialFeedsContainer>  
              {/* <SideBarContainer>  
                <FeaturedChallenges history={history} />
              </SideBarContainer> */}
            </Layout>
          </Tab>
          <Tab eventKey={2} title={t("WELLNESS")}>
            <Layout>
              <SocialFeedsContainer>
                <FeedsContainer>
                  <EditProfilePadding bottomBorder={true}>
                    <SettingsName EditPage>
                      {t("Wellness Interest")}
                    </SettingsName>
                    <div>
                      { WellnessData.map((title, index) => (
                        <RegistrationEdit 
                          list={wellnessInterests} 
                          title={t(title)} 
                          id={title}
                          key={index} 
                          checkData={interestArr}
                          onChangeText='interestArr'
                          onCheckboxData={this.onCheckboxData}
                          interest={true}
                        />
                      ))}
                    </div>
                  </EditProfilePadding>
                  <EditProfilePadding bottomBorder={true}>
                    <SettingsName EditPage>
                      {t("Fitness Goals")}:
                    </SettingsName>
                    <div>
                      { fitnessData.map((title, index) => (
                        <RegistrationEdit 
                          list={fitnessGoals} 
                          title={t(title)} 
                          id={title}
                          key={index}
                          checkData={goalArr}
                          onChangeText='goalArr'
                          onCheckboxData={this.onCheckboxData}
                        />
                      ))}
                    </div>
                  </EditProfilePadding>

                  {user.user_type !== 2 ? <EditProfilePadding bottomBorder={true}>
                    <SettingsName EditPage style={{marginBottom:"10px"}}>
                      {t("My General Health Information")}:
                    </SettingsName>
                    {this.dropDownDetails(exerciseDetail, exercise, 'exercise')}
                    {this.dropDownDetails(smokeDetails, smoke, 'smoke')}
                    {this.dropDownDetails(physicalExamDetails, physicalExamDropdown, 'physicalExamDropdown')}
                    {this.dropDownDetails(averageSleepDetails, averageSleepDropdown, 'averageSleepDropdown')}
                    {this.dropDownDetails(averageWaterDetails, averageWaterDropdown, 'averageWaterDropdown')}
                    {this.dropDownDetails(experiencePainDetails, experiencePainDropdown, 'experiencePainDropdown')}
                    {this.dropDownDetails(prescriptionDetails, prescriptionDropdown, 'prescriptionDropdown')}
                    {this.dropDownDetails(minuteActiveDetails, minuteActiveDropdown, 'minuteActiveDropdown')}
                  </EditProfilePadding> : null
                  }
                  <EditProfilePadding>
                    {/* <SaveButton onClick={this.updateWellnessDashboard} addMargin 
                    disabled={user && user.user_type !== 2 && (wellnessSave || disableButtton=='save_wellness')}>
                      {t("SAVE")}
                    </SaveButton> */}
                    <CommonButton
                      btnType={"rounded"}
                      onClick={this.updateWellnessDashboard}
                      addMargin
                      disabled={user && user.user_type !== 2 && (wellnessSave || disableButtton=='save_wellness')}
                      styles={{width:'158px', height:'35px', marginBottom:'55px'}}
                      title={t('SAVE')}
                    />
                  </EditProfilePadding>
                </FeedsContainer>
              </SocialFeedsContainer>
              {/* <SideBarContainer>  
                <FeaturedChallenges history={history}/>
              </SideBarContainer> */}
            </Layout>
          </Tab>
          <Tab eventKey={3} title={t("UPDATE CREDENTIALS")}>
            <Layout>
              <SocialFeedsContainer>
                <FeedsContainer>
                  <UpdatedCredentials resetPassword={this.props.resetPassword}/>
                </FeedsContainer>
              </SocialFeedsContainer>
              {/* <SideBarContainer>  
                <FeaturedChallenges history={history}/>
              </SideBarContainer> */}
            </Layout>
          </Tab>
          <Tab eventKey={4} title={t("Emails Notifications")}>
            <Layout>
              <SocialFeedsContainer>
                <FeedsContainer>
                  <UserEmailNotifications />
                </FeedsContainer>
              </SocialFeedsContainer>
              {/* <SideBarContainer>  
                <FeaturedChallenges history={history}/>
              </SideBarContainer> */}
            </Layout>
          </Tab>
          <Tab eventKey={5} title={t("Units")}>
            <Layout>
              <SocialFeedsContainer>
                <FeedsContainer>
                  <MatrixConverionMain />
                </FeedsContainer>
              </SocialFeedsContainer>
              {/* <SideBarContainer>
                <FeaturedChallenges history={history}/>
              </SideBarContainer> */}
            </Layout>
          </Tab>
        </StyledTab>
      </div>
    );
  }
}

Settings.propTypes = {
  getUserProfileDetails: PropTypes.func.isRequired,
  updateUserDataDetails: PropTypes.func.isRequired,
  networkError: PropTypes.string,
  user: PropTypes.object,
  health: PropTypes.array,
  getUserHealthDetails: PropTypes.func.isRequired,
  removeProfileAPI: PropTypes.func.isRequired,
  wellnessInterests: PropTypes.array,
  fetchWellnessInterest: PropTypes.func.isRequired,
  fitnessGoals: PropTypes.array,
  fetchFitnessGoals: PropTypes.func.isRequired,
  interest: PropTypes.array,
  goal: PropTypes.object,
  updateWellnessDetail: PropTypes.func.isRequired,
  resetPassword: PropTypes.func.isRequired,
  locationDetails: PropTypes.array,
  departmentDetails: PropTypes.array,
  history: PropTypes.object.isRequired,
  uploadProfile: PropTypes.func.isRequired,
  t: PropTypes.func
 
};

const mapStateToProps = (state) => ({
  networkError: state.profileData.networkError,
  user: state.profileData.user,
  goal: state.profileData.wellnessGoals,
  interest: state.profileData.interest,
  health: state.profileData.health,
  wellnessInterests: state.register.wellnessInterests,
  fitnessGoals: state.register.fitnessGoals,
  locationDetails: state.register.locationDetails,
  departmentDetails: state.register.departmentDetails
});

const mapDispatchToProps = (dispatch) => ({
  getUserProfileDetails: () => dispatch(getUserProfile()),
  getUserHealthDetails: () => dispatch(getUserHealth()),
  updateUserDataDetails: (userData, callback) => dispatch(putUpdateUserData(userData, callback)),
  removeProfileAPI: (userData, callback) => dispatch(removeProfileAPI(userData, callback)),
  fetchWellnessInterest: () => dispatch(getWellnessInterests()),
  fetchFitnessGoals: () => dispatch(getFitnessGoals()),
  updateWellnessDetail: (wellnessData, callback) => dispatch(putUpdateWellnessData(wellnessData, callback)),
  resetPassword: (resetData) => dispatch(changePassword(resetData)),
  uploadProfile: (profile) => dispatch(uploadProfile(profile))
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Settings));